import React, { Component } from 'react'
import logo from "../img/logo.svg";
import linkedin from "../img/social/linkedin.svg";

class Copyright extends Component {
  render() {
    return <span>{(new Date().getFullYear())}</span>;
  }
}

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer__container">
          <div className="footer__copyright">
            <img src={logo} alt="Michelle Pakla" className="footer__logo"/>
            <span>&copy; <Copyright/></span>
          </div>
          <div className="footer__social">
            <a title="linkedin" href="https://www.linkedin.com/in/michellepakla/"  target="_blank" rel="noopener noreferrer">
              <img src={linkedin} alt="linkedin"/>
            </a>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
